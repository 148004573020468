const actionsTemplate = (category) => [
    { id: 1, text: `Receive Information about Declaration of Crisis by KPC CEO`, checked: false, category },
    { id: 2, text: `Direct the HSSE Manager to Activate the CMC.`, checked: false, category },
    { id: 3, text: `Assign one of the DECOs to act as PIC CMT Leader.`, checked: false, category },
    { id: 4, text: `Proceed to KPC CMC.`, checked: false, category },
    { id: 1, text: `Act as an adviser to the KPC Crisis Management Team in matters related to PIC core business.`, checked: false, category },
    { id: 2, text: `Provide any Supports Requested by KPC Crisis Management Team if Needed.`, checked: false, category },
    { id: 3, text: `Continuously analyze PIC status & Identify direct or indirect risks associated & Report it to KPC.`, checked: false, category },
    { id: 4, text: `Identify measures taken or need to be taken to limit the effect of crisis on PIC.`, checked: false, category }
];


const ExternalIncidentData = {
    "External Incident": {
        "Communication Chart": "/media/external.png",  // Ensure this points to the correct relative path
        "Response Action": {
            "Security": {
                "Wars": {
                    actions: {
                        incidentOccurs: actionsTemplate("Wars"),
                    }
                },
                "Regional or Local Instability": {
                    actions: {
                        incidentOccurs: actionsTemplate("Regional or Local Instability"),
                    }
                },
                "Nuclear Impact": {
                    actions: {
                        incidentOccurs: actionsTemplate("Nuclear Impact"),
                    }
                },
                "Missile Attacks": {
                    actions: {
                        incidentOccurs: actionsTemplate("Missile Attacks"),
                   
                    }
                },
                "Unauthorized Drones": {
                    actions: {
                        incidentOccurs: actionsTemplate("Unauthorized Drones"),
                    }
                },
                "Security Breaches": {
                    actions: {
                        incidentOccurs: actionsTemplate("Security Breaches"),
                    }
                },
                "Terrorist Attacks": {
                    actions: {
                        incidentOccurs: actionsTemplate("Terrorist Attacks"),
                    }
                },
                "Sabotage Acts": {
                    actions: {
                        incidentOccurs: actionsTemplate("Sabotage Acts"),
                    }
                },
                "Missiles Attack": {
                    actions: {
                        incidentOccurs: actionsTemplate("Missiles Attack"),
                    }
                }
            },
            "Industrial": {
                "Personnel": {
                    actions: {
                        incidentOccurs: actionsTemplate("Personnel"),
                    }
                },
                "Explosion": {
                    actions: {
                        incidentOccurs: actionsTemplate("Explosion"),
                    }
                },
                "Fire": {
                    actions: {
                        incidentOccurs: actionsTemplate("Fire"),
                    }
                },
                "Volume Spills": {
                    actions: {
                        incidentOccurs: actionsTemplate("Volume Spills"),
                    }
                },
                "Gas Leaks": {
                    actions: {
                        incidentOccurs: actionsTemplate("Gas Leaks"),
                    }
                }
            },
            "Natural Disasters": {
                "Flooding": {
                    actions: {
                        incidentOccurs: actionsTemplate("Flooding"),
                    }
                },
                "Earthquake": {
                    actions: {
                        incidentOccurs: actionsTemplate("Earthquake"),
                    }
                }
            },
            "Manpower Strikes": {
                actions: {
                    incidentOccurs: actionsTemplate("Manpower Strikes"),
                }
            },
            "Cyber Security": {
                actions: {
                    incidentOccurs: actionsTemplate("Cyber Security"),
                }
            }
        }
    }
};

export default ExternalIncidentData;
