const actionsTemplate = (category) => [
  { id: 1, text: `Operations Manager / HSSE Manager shall Notify the M&M DCEO.`, checked: false, category },
  { id: 2, text: `M&M DCEO shall Notify PIC CEO about the Situation.`, checked: false, category },
  { id: 3, text: `PIC CEO shall Notify KPC CEO about the Incident & Provide Frequent updates about the Situation`, checked: false, category },
  { id: 4, text: `Direct the Department Managers to Prepare List of Staff Required “Essential Staff” during the Crises`, checked: false, category },
  { id: 5, text: `Direct the Department Managers to Intercept the Available Resources & Identify Additional Ones If Required`, checked: false, category },
  { id: 6, text: `Stay Steady to Proceed to CMC or KPC CMC upon Notification from KPC CEO`, checked: false, category }
];

const crisisActionsTemplate = (category) => [
  { id: 7, text: `Direct the HSSE Manager to Activate the CMC.`, checked: false, category },
  { id: 8, text: `Review the Situation & Conduct P.E.A.R “People, Environment, Asset, Reputation” Assessment to Notify KPC CEO`, checked: false, category },
  { id: 9, text: `Ensure that the crisis is being managed in accordance with the applicable PIC Emergency Plans “Including Evacuation Plan for Non-Essential staff”.`, checked: false, category },
  { id: 10, text: `Keep Monitoring & Manage the Situation till the Incident is Controlled`, checked: false, category }
];

const flowchartData = {
  "PP Plant Incident": {
    "Communication Chart": "/media/PPplant.png",
    "Response Action": {
      "Security": {
        "Missile Attacks": {
          actions: {
            incidentOccurs: actionsTemplate("Missile Attacks"),
            crisisDeclared: crisisActionsTemplate("Missile Attacks")
          }
        },
        "Unauthorized Drones": {
          actions: {
            incidentOccurs: actionsTemplate("Unauthorized Drones"),
            crisisDeclared: crisisActionsTemplate("Unauthorized Drones")
          }
        },
        "Security Breaches": {
          actions: {
            incidentOccurs: actionsTemplate("Security Breaches"),
            crisisDeclared: crisisActionsTemplate("Security Breaches")
          }
        },
        "Sabotage Acts": {
          actions: {
            incidentOccurs: actionsTemplate("Sabotage Acts"),
            crisisDeclared: crisisActionsTemplate("Sabotage Acts")
          }
        },
        "Terrorist Attacks": {
          actions: {
            incidentOccurs: actionsTemplate("Terrorist Attacks"),
            crisisDeclared: crisisActionsTemplate("Terrorist Attacks")
          }
        }
      },
      "Industrial": {
        "Personnel": {
          actions: {
            incidentOccurs: actionsTemplate("Personnel"),
            crisisDeclared: crisisActionsTemplate("Personnel")
          }
        },
        "Explosions": {
          actions: {
            incidentOccurs: actionsTemplate("Explosions"),
            crisisDeclared: crisisActionsTemplate("Explosions")
          }
        },
        "Fire": {
          actions: {
            incidentOccurs: actionsTemplate("Fire"),
            crisisDeclared: crisisActionsTemplate("Fire")
          }
        },
        "Volume Spills": {
          actions: {
            incidentOccurs: actionsTemplate("Volume Spills"),
            crisisDeclared: crisisActionsTemplate("Volume Spills")
          }
        },
        "Gas Leaks": {
          actions: {
            incidentOccurs: actionsTemplate("Gas Leaks"),
            crisisDeclared: crisisActionsTemplate("Gas Leaks")
          }
        }
      },
      "Natural Disasters": {
        "Flooding": {
          actions: {
            incidentOccurs: actionsTemplate("Flooding"),
            crisisDeclared: crisisActionsTemplate("Flooding")
          }
        },
        "Earthquake": {
          actions: {
            incidentOccurs: actionsTemplate("Earthquake"),
            crisisDeclared: crisisActionsTemplate("Earthquake")
          }
        }
      },
      "Cyber Security": {
        actions: {
          incidentOccurs: actionsTemplate("Cyber Security"),
          crisisDeclared: crisisActionsTemplate("Cyber Security")
        }
      },
      "Manpower Strikes": {
        actions: {
          incidentOccurs: actionsTemplate("Manpower Strikes"),
          crisisDeclared: crisisActionsTemplate("Manpower Strikes")
        }
      }
    }
  }
};

export default flowchartData;
