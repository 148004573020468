import React, { useState } from 'react';
import {
  Text, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody,
  ModalCloseButton, useDisclosure, VStack, Checkbox, Box, Center
} from '@chakra-ui/react';
import ExternalIncidentData from '../data/externalIncidentsData';  // Specific to ExternalIncidentComponent

const ExternalIncidentComponent = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentSelection, setCurrentSelection] = useState({});
  const [path, setPath] = useState([]);

  // Debugging logs
  console.log(ExternalIncidentData); // Check if data is correctly loaded
  console.log("Initial Path:", path);

  const handleInitialOpen = (incidentType) => {
    console.log("Incident Type:", incidentType); // Log incident type to ensure correct type is passed
    const initialData = ExternalIncidentData[incidentType];
    console.log("Initial Data:", initialData); // Log the data fetched based on the incident type

    if (initialData) {
      setCurrentSelection(initialData);
      setPath([incidentType]);
      onOpen();
    } else {
      console.error(`Incident type "${incidentType}" not found in data.`);
    }
  };

  const handleNodeClick = (key) => {
    console.log("Node Click:", key); // Log the key clicked
    let nextData;

    if (currentSelection[key]) {
      nextData = currentSelection[key];
    } else if (key === 'Response Action' && ExternalIncidentData['External Incident']['Response Action']) {
      nextData = ExternalIncidentData['External Incident']['Response Action'];
    } else {
      console.error(`Key "${key}" is not defined in currentSelection or ExternalIncidentData.`);
      return;
    }

    setCurrentSelection(nextData);
    setPath((prev) => [...prev, key]);
  };

  const handleModalClose = () => {
    onClose();
    setCurrentSelection({});
    setPath([]);
  };

  const handleCheckboxChange = (index, section) => {
    const updatedActions = { ...currentSelection };
    updatedActions.actions[section][index].checked = !updatedActions.actions[section][index].checked;
    setCurrentSelection({ ...updatedActions });
  };

  const renderActionTextBoxes = (actions, section) => (
    actions.map((action, index) => (
      <Box key={index} display="flex" alignItems="center" mt={4}>
        <Checkbox isChecked={action.checked} onChange={() => handleCheckboxChange(index, section)} mr={2} />
        <Text flex="1" whiteSpace="normal" wordBreak="break-word">{action.text}</Text>
      </Box>
    ))
  );

  const handleOpenChart = () => {
    const chartUrl = ExternalIncidentData['External Incident']['Communication Chart'];
    if (chartUrl) {
      window.open(chartUrl, '_blank');
    } else {
      console.error('Communication chart URL is not defined.');
    }
  };

  const renderContent = () => {
    if (path.length === 1) {
      return (
        <VStack spacing={4}>
          <Button colorScheme="red" onClick={() => handleNodeClick('Response Action')}>Response Action</Button>
          <Button colorScheme="red" onClick={handleOpenChart}>Communication Chart</Button>
        </VStack>
      );
    } else if (currentSelection.actions) {
      return (
        <>
          <Text fontSize="lg" fontWeight="bold" mt={2}> If Crisis is Declared :</Text>
          {renderActionTextBoxes(currentSelection.actions.incidentOccurs, 'incidentOccurs')}
        </>
      );
    } else if (Object.keys(currentSelection).length > 0) {
      return (
        <VStack spacing={4}>
          {Object.keys(currentSelection).map((key) => (
            <Button key={key} colorScheme="red" onClick={() => handleNodeClick(key)}>{key}</Button>
          ))}
        </VStack>
      );
    }
    return null;
  };

  return (
    <Center height="auto" flexDirection="column">
      <Button px={8} 
  py={8}
  
  fontSize="2xl"colorScheme="red" onClick={() => handleInitialOpen('External Incident')} size="lg" >
        External Incident
      </Button>
      <Modal isOpen={isOpen} onClose={handleModalClose} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Response Actions</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{renderContent()}</ModalBody>
        </ModalContent>
      </Modal>
    </Center>
  );
};

export default ExternalIncidentComponent;
