const actionsTemplate = (category) => [
    { id: 1, text: `Receive Information about the Situation from Business Monitoring Manager / EQUATE CEO. `, checked: false, category },
    { id: 2, text: `Notify KPC CEO about the Incident & Provide Frequent updates about the Situation.`, checked: false, category },
    { id: 3, text: `Direct Business Monitoring Manager to Stay Steady for Joining the PIC CMC room.`, checked: false, category },
    { id: 4, text: `Stay Steady to Proceed to PIC CMC or KPC CMC upon Notification from KPC CEO.`, checked: false, category }  ];
  
  const crisisActionsTemplate = (category) => [
    { id: 5, text: `Notify KPC CEO about the Situation to Decide either to Activate KPC CMC or not.`, checked: false, category },
    { id: 6, text: `Direct the HSSE Manager to Activate the CMC & to call the Business Monitoring Manager.`, checked: false, category },
    { id: 7, text: `Receive the Situation Updates from the PIC Representative in EQUATE CMC “Production Team Leader”.`, checked: false, category },
    { id: 8, text: `Review the Situation & Conduct P.E.A.R “People, Environment, Asset, Reputation” Assessment to Notify KPC CEO.`, checked: false, category },
    { id: 9, text: `Keep Monitoring & Manage the Situation till the Incident is Controlled & All Clear is Declared by EQUATE`, checked: false, category },
    { id: 10, text: `Notify KPC CEO “All Clear is Announced”  `, checked: false, category },

  ];
  
  const JointVentureData = {
    "Joint Venture": {
      "Communication Chart": "/media/jv.png",  // Ensure this points to the correct relative path
      "Response Action": {
        "Security": {
          "Missile Attacks": {
            actions: {
              incidentOccurs: actionsTemplate("Missile Attacks"),
              crisisDeclared: crisisActionsTemplate("Missile Attacks")
            }
          },
          "Unauthorized Drones": {
            actions: {
              incidentOccurs: actionsTemplate("Unauthorized Drones"),
              crisisDeclared: crisisActionsTemplate("Unauthorized Drones")
            }
          },
          "Security Breaches": {
            actions: {
              incidentOccurs: actionsTemplate("Security Breaches"),
              crisisDeclared: crisisActionsTemplate("Security Breaches")
            }
          },
          "Terrorist Attacks": {
            actions: {
              incidentOccurs: actionsTemplate("Terrorist Attacks"),
              crisisDeclared: crisisActionsTemplate("Terrorist Attacks")
            }
          },
          "Sabotage Acts": {
            actions: {
              incidentOccurs: actionsTemplate("Sabotage Acts"),
              crisisDeclared: crisisActionsTemplate("Sabotage Acts")
            }
          }
        },
        "Industrial": {
          "Personnel": {
            actions: {
              incidentOccurs: actionsTemplate("Personnel"),
              crisisDeclared: crisisActionsTemplate("Personnel")
            }
          },
          "Explosion": {
            actions: {
              incidentOccurs: actionsTemplate("Explosion"),
              crisisDeclared: crisisActionsTemplate("Explosion")
            }
          },
          "Fire": {
            actions: {
              incidentOccurs: actionsTemplate("Fire"),
              crisisDeclared: crisisActionsTemplate("Fire")
            }
          },
          "Volume Spills": {
            actions: {
              incidentOccurs: actionsTemplate("Volume Spills"),
              crisisDeclared: crisisActionsTemplate("Volume Spills")
            }
          },
          "Gas Leaks": {
            actions: {
              incidentOccurs: actionsTemplate("Gas Leaks"),
              crisisDeclared: crisisActionsTemplate("Gas Leaks")
            }
          }
        },
        "Natural Disasters": {
          "Flooding": {
            actions: {
              incidentOccurs: actionsTemplate("Flooding"),
              crisisDeclared: crisisActionsTemplate("Flooding")
            }
          },
          "Earthquake": {
            actions: {
              incidentOccurs: actionsTemplate("Earthquake"),
              crisisDeclared: crisisActionsTemplate("Earthquake")
            }
          }
        },
        "Manpower Strikes": {
          actions: {
            incidentOccurs: actionsTemplate("Manpower Strikes"),
            crisisDeclared: crisisActionsTemplate("Manpower Strikes")
          }
        },
        "Cyber Security": {
          actions: {
            incidentOccurs: actionsTemplate("Cyber Security"),
            crisisDeclared: crisisActionsTemplate("Cyber Security")
          }
        }
      }
    }
  };
  
  export default JointVentureData;
  