import { Box, ChakraProvider, Image, Center, Text, Flex } from '@chakra-ui/react';
import FlowchartComponent from './components/flowchart';
import JointVentureComponent from './components/Jointventures';
import Logo from "../src/pic_main.png";
import ExternalIncidentComponent from './components/externalIncident';

function App() {
  return (
    <ChakraProvider>
      <Center flexDirection="column" mt={4}>
        <Image src={Logo} alt="Logo" boxSize="300px" objectFit="cover" my={10}/>
        <Text fontSize="lg" fontWeight="bold">Crisis Management Response Guide</Text>
        <Text fontSize="md">HSSE Department</Text>
        <Box>
          <FlowchartComponent />
          <JointVentureComponent />
          <ExternalIncidentComponent />
        </Box>
        <Flex flexDirection="column" alignItems="center" width="full" mt={3}>
          <Text fontSize="sm">Reviewed by: Ahmad AlSaegh (Safety Team Leader)</Text>
          <Text fontSize="sm">Approved by: Rasheed Hasan Alajmi (HSSE Manager)</Text>
        </Flex>
      </Center>
    </ChakraProvider>
  );
}

export default App;
