import React, { useState } from 'react';
import {
  Text,
  Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, useDisclosure, VStack, Checkbox, Box, Center
} from '@chakra-ui/react';
import flowchartData from '../data/flowchartData';

const FlowchartComponent = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentSelection, setCurrentSelection] = useState({});
  const [path, setPath] = useState([]);

  // Opens the modal for the initial selection (first page)
  const handleInitialOpen = () => {
    setCurrentSelection(flowchartData['PP Plant Incident']);
    setPath(['PP Plant Incident']);
    onOpen();
  };

  // Handle navigation through the flowchart data
  const handleNodeClick = (key) => {
    let nextData;

    // Check if key is inside 'currentSelection' and navigate deeper
    if (currentSelection[key]) {
      nextData = currentSelection[key];
    } else if (key === 'Response Action') {
      nextData = flowchartData['PP Plant Incident']['Response Action'];
    } else {
      const newPath = [...path, key];
      nextData = newPath.reduce((acc, cur) => acc[cur], flowchartData['PP Plant Incident']);
    }

    setCurrentSelection(nextData);
    setPath((prev) => [...prev, key]);
  };

  // Close the modal and reset the navigation path
  const handleModalClose = () => {
    onClose();
    setCurrentSelection({});
    setPath([]);
  };

  // Handle checkbox change
  const handleCheckboxChange = (index, section) => {
    const updatedActions = { ...currentSelection };
    updatedActions.actions[section][index].checked = !updatedActions.actions[section][index].checked;
    setCurrentSelection({ ...updatedActions });
  };

  // Render actions in textboxes with checkboxes
  const renderActionTextBoxes = (actions, section) => (
    actions.map((action, index) => (
      <Box key={index} display="flex" alignItems="center" mt={4}>
        <Checkbox
          isChecked={action.checked}
          onChange={() => handleCheckboxChange(index, section)}
          mr={2}
        />
    <Text
        flex="1"
        whiteSpace="normal" // Allow text to wrap normally
        wordBreak="break-word" // Break long words to prevent overflow
      >
        {action.text}
      </Text>
      </Box>
    ))
  );

  // Open the communication chart in a new tab
  const handleOpenChart = () => {
    const chartUrl = flowchartData['PP Plant Incident']['Communication Chart'];
    window.open(chartUrl, '_blank'); // Opens the image in a new tab
  };

  // Render buttons for the next level of navigation or actions if at the final level
  const renderContent = () => {
    if (path.length === 1) { 
      // This is the first page of the modal
      return (
        <VStack spacing={4}>
          <Button colorScheme="blue" onClick={() => handleNodeClick('Response Action')}>
            Response Action
          </Button>
          <Button colorScheme="blue" onClick={handleOpenChart}>
            Communication Chart
          </Button>
        </VStack>
      );
    } else if (currentSelection && currentSelection.actions) {
      // Render actions when in action stages
      return (
        <>
          <Text fontSize="lg" fontWeight="bold" mt={2}>Incase Incident Occurs:</Text>
          {renderActionTextBoxes(currentSelection.actions.incidentOccurs, 'incidentOccurs')}
          <Text fontSize="lg" fontWeight="bold" mt={4}>If Crisis is Declared by KPC CEO “Only”:</Text>
          {renderActionTextBoxes(currentSelection.actions.crisisDeclared, 'crisisDeclared')}
        </>
      );
    } else if (Object.keys(currentSelection).length > 0) {
      // Render next level navigation buttons
      return (
        <VStack spacing={4}>
          {Object.keys(currentSelection).map((key) => {
            if (typeof currentSelection[key] === 'object' && key !== 'actions') {
              return (
                <Button key={key} colorScheme="blue" onClick={() => handleNodeClick(key)} mt={5} ml={5}>
                  {key}
                </Button>
              );
            }
            return null;
          })}
        </VStack>
      );
    }
    return null; // For cases where no content exists
  };

  return (
    <Center height="auto" flexDirection="column">
      <Button px={8} 
  py={8} 
  width="250px" 
  fontSize="2xl" colorScheme="blue" onClick={handleInitialOpen} size="lg" mt={6}> PP Plant Incident</Button>
      <Modal isOpen={isOpen} onClose={handleModalClose} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Response Actions</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {renderContent()}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Center>
  );
};

export default FlowchartComponent;
